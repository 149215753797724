import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes, { number } from 'prop-types';
import { Link } from 'gatsby';
import Icon from '../Icon';

import elementOffset from '../../utils/elementOffset';

import './scrollspy.scss';

function Scrollspy({ children }) {
  const [activeSection, setActiveSection] = useState(0);
  const [menuItems, setMenuItems] = useState([]);

  const containerRef = useRef();
  const spyRef = useRef();

  const scrollHandler = useCallback(() => {
    if (menuItems.length > 0) {
      let lastID = 0;

      menuItems.forEach(({ el, id }) => {
        if (window.pageYOffset > elementOffset(el, 'top') - 300) lastID = id;
      });

      if (spyRef.current?.children.length) {
        spyRef.current.scrollTo(
          spyRef.current.children[menuItems.findIndex((i) => i.id === lastID)]
            .offsetLeft,
          0,
        );
      }

      setActiveSection(lastID);
    }
  }, [menuItems]);

  const linkClickHandler = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    window.scrollTo({
      top:
        elementOffset(
          document.querySelector(
            `[data-spy-id="${new URL(e.target.href).hash.replace('#', '')}"]`,
          ),
          'top',
        ) - 250,
      left: 0,
      behavior: 'smooth',
    });
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      const elements = containerRef.current.querySelectorAll('[data-spy-id]');

      setMenuItems(
        Array.from(elements).map((el) => ({
          id: el.dataset.spyId,
          label: el.dataset.spyTitle || el.dataset.spyId,
          icon: el.dataset.spyIcon || el.dataset.spyIcon,
          el,
        })),
      );
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);

    return () => window.removeEventListener('scroll', scrollHandler);
  }, [scrollHandler]);

  return (
    <div ref={containerRef}>
      <div className="container" style={{ position: 'relative' }}>
        <div className="row">
          <div className="col col-4 scrollspy-mobile-container">
            <ul
              ref={spyRef}
              className={`scrollspy-list ${
                activeSection !== 0 ? 'is-sticky' : ''
              } `}>
              {menuItems.map(({ id, label, icon }) => (
                <li
                  key={id}
                  className={id === activeSection ? 'is-current' : ''}>
                  <Link key={id} to={`#${id}`} onClick={linkClickHandler}>
                    <img src={icon} alt={label} />
                    {label}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col col-8">{children}</div>
        </div>
      </div>
    </div>
  );
}

Scrollspy.propTypes = {
  // leadContent: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
};

export default Scrollspy;
