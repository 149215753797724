import React, { useCallback, useState, useEffect, useRef } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Scrollspy from '../components/Scrollspy/Scrollspy';
import Accordion from '../components/Accordion/Accordion';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import useMediaQuery from '../hooks/useMediaQuery';
import '../styles/faq.scss';

import FooterCta from '../components/footerCta/FooterCta';

function FaqPage() {
  const data = useStaticQuery(graphql`
    query FaqQuery {
      allContentfulFaq {
        nodes {
          question
          answer {
            raw
          }
          category {
            title
            icon {
              url
            }
            id
          }
          id
          subcategory {
            id
            title
          }
        }
      }
      allContentfulFaqCategory {
        nodes {
          title
          id
          icon {
            url
          }
        }
      }
      allContentfulFaqSubcategory {
        nodes {
          id
          title
        }
      }
    }
  `);

  const isMobile = useMediaQuery('(max-width: 767px)');

  const getGroups = (n) => {
    const nodes = data.allContentfulFaq.nodes.filter(
      (i) => i.category?.id === n.id,
    );

    const subCategories = Array.from(
      new Set(nodes.map((i) => i.subcategory?.id || 'none')),
    ).map((c) =>
      [
        ...data.allContentfulFaqSubcategory.nodes,
        { id: 'none', title: null },
      ].find((i) => i.id === c),
    );

    return subCategories.map((c) => ({
      ...c,
      data: nodes.filter((k) => (k.subcategory?.id || 'none') === c.id),
    }));
  };
  const [searchFilter, setSearchFilter] = useState('');
  const [searchDropdown, setsearchDropdown] = useState(false);

  // const getFilter = () => {
  //   let filteredPosts = [];

  //   if (searchFilter.length > 0)
  //     filteredPosts = data.filter(
  //       (allContentfulFaq) =>
  //         allContentfulFaq.nodes.question
  //           .toLowerCase()
  //           .indexOf(searchFilter.toLowerCase()) > -1,
  //     );

  //   return filteredPosts;
  // };

  const searchInputHandler = (e) => {
    setSearchFilter(e.target.value);
    setsearchDropdown(true);
  };

  const scrollHandler = (n) => (e) => {
    e.preventDefault();
    setSearchFilter(n.question);
    setsearchDropdown(false);

    const elem = document.getElementById(n.id).offsetTop;

    let scrollValue;

    if (isMobile) {
      scrollValue = 180;
    } else {
      scrollValue = 460;
    }

    if (elem) {
      window.scrollTo({
        top: elem + scrollValue,
      });
    }
  };

  const renderSearchResult = useCallback(() => {
    if (searchFilter.length) {
      const result = data.allContentfulFaq.nodes.filter((n) =>
        new RegExp(searchFilter, 'i').test(`${n.question} ${n.answer.raw}`),
      );

      if (result.length) {
        return result.map((n) => (
          <a key={n.id} href={`#${n.id}`} onClick={scrollHandler(n)}>
            {n.question}
          </a>
        ));
      }
      return <a href="#!">No result found</a>;
    }

    return null;
  }, [searchFilter]);

  const searchFaq = useRef();
  const searchFaqContainer = useRef();
  const fixpastThis = useRef();
  const heightCompunsate = useRef();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (fixpastThis.current) {
        if (window.pageYOffset > fixpastThis.current.offsetTop - 180) {
          searchFaqContainer.current.classList.add('fixed');
        } else {
          searchFaqContainer.current.classList.remove('fixed');
        }
      }
    });
  }, []);

  useEffect(() => {
    if (searchFaq.current && isMobile) {
      const height = searchFaq.current.offsetHeight;
      heightCompunsate.current.style.height = `${height}px`;
    }
  }, [isMobile]);

  return (
    <Layout lightHeader fixedHeader>
      <SEO
        title="At your service | Aeldra"
        description="For general information regarding Aeldra and our other products and services please go through the FAQs. For further queries you can e-mail us concierge@aeldra.com or call the 24X7 Aeldra Concierge at India Land Line Number: +91-80-69559800 or +1 844 333 3101 (toll-free within the U.S.)."
      />
      <div className="inner-layout-hero black-bg" ref={searchFaqContainer}>
        <div className="container">
          <div className="text-center">
            <h4>FAQs</h4>
            <h1>We've got your back.</h1>
            <div className="search-height-compusate" ref={heightCompunsate} />
            <div className="search-wrapper" ref={searchFaq}>
              <div className="search-faq">
                <svg
                  className="search-icon"
                  width="13"
                  height="13"
                  fill="none"
                  viewBox="0 0 13 13">
                  <path
                    fill="#fff"
                    d="M5.14.156a4.984 4.984 0 0 1 3.978 7.989l2.868 2.869a.688.688 0 0 1-.972.972L8.145 9.118A4.985 4.985 0 1 1 5.141.156Zm0 1.375a3.61 3.61 0 1 0 0 7.219 3.61 3.61 0 0 0 0-7.219Z"
                  />
                </svg>

                <input
                  type="text"
                  className="form-control"
                  placeholder="Search for......"
                  value={searchFilter}
                  onChange={searchInputHandler}
                />

                <div
                  className="search-dropdown"
                  style={{ display: searchDropdown ? 'block' : 'none' }}>
                  <div className="search-overflow">{renderSearchResult()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="section-gap faq-section-main" ref={fixpastThis}>
        <div className="faq-main">
          <Scrollspy className="faq-scroll-list">
            <div className="faq-desc-area">
              {data.allContentfulFaqCategory.nodes.map((n) => (
                <div
                  key={n.id}
                  className="single-faq-list"
                  data-spy-title={n.title}
                  data-spy-id={n.id}
                  data-spy-icon={n.icon.url}>
                  <h3>{n.title}</h3>
                  {getGroups(n).map((g) => (
                    <React.Fragment key={g.id}>
                      {!!g.title && <h4 className="body-font">{g.title}</h4>}

                      <Accordion
                        id={g.id}
                        data={g.data.map((i) => ({
                          id: i.id,
                          question: i.question,
                          answer: documentToReactComponents(
                            JSON.parse(i.answer.raw),
                          ),
                        }))}
                      />
                    </React.Fragment>
                  ))}
                </div>
              ))}
            </div>
          </Scrollspy>
        </div>
      </section>

      <FooterCta />
    </Layout>
  );
}

export default FaqPage;
